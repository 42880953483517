.cardBody {
  padding: 0px !important;

  .titlesList {
    display: flex;
    flex-direction: column;
    gap: 1px;
    background-color: var(--ui-divider);

    li {
      background-color: #fff;

      a {
        display: flex;
        flex-direction: column;

        .title {
          font-size: 1rem;
          font-weight: bold;
        }

        .location {
          color: var(--ui-mutedText);
          font-size: 1rem;
        }
      }
    }
  }
}