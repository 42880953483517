.BlogCarousel {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;

  .postImageContainer {
    position: relative;
    width: 100%;
    height: 200px;
    border-radius: 4px;
    overflow: hidden;

    .prev {
      position: absolute;
      left: 0;
      z-index: 2;
    }

    .next {
      position: absolute;
      right: 0;
    }
  }
}