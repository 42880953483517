.cardBody {
  padding: 0px 16px !important;
}

.topicsList {
  display: flex;
  flex-direction: column;
  gap: 1px;
  background-color: var(--ui-divider);

  li {
    background-color: #fff;

    a {
      position: relative;
      display: flex;
      gap: 16px;
      align-items: center;
      padding: 16px 8px;

      .topicImg {
        position: relative;
        width: 110px;
        height: 110px;
        min-width: 110px;
        min-height: 110px;

        img {
          border-radius: 4px;
        }
      }

      .topicDetails {
        a {
          font-weight: bold;
        }

        .excerpt {
          color: var(--ui-mutedText);
          font-size: 1rem;
        }
      }
    }
  }
}