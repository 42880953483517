@use 'ui/styles/variables' as vars;

.HomeHeroPromo {
  margin-top: 20px;
  max-width: 100vw;
  overflow: hidden;
}

@include vars.mobileOnly() {
  .HomeHeroPromoInner {
    margin-bottom: initial;
    padding-bottom: 10px;
  }
}