@use 'ui/styles/variables' as vars;

.Searches {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
  width: auto;
  max-width: 100%;
  margin: 0 auto;
  min-width: 75%;
  justify-content: center;
  height: 72px;
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    opacity: 0;
    transition: opacity var(--transition-speed), transform var(--transition-speed);

    &.fadeOut {
      opacity: 0 !important;
    }

    &.fadeIn {
      opacity: 1;
    }

    .pill {

      gap: 4px;

    }
  }

  @include vars.mobileOnly() {
    height: 200px;
  }


}