.logoList {
  margin-bottom: 1rem;

  li {
    background: url(/static/i/US-titles.png) no-repeat 50% 0;
    background-size: 310px 197px;

    height: 72px;
    width: 100%;

    &:hover {
      background-color: var(--background-faintBlue);
    }

    a {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

    }

    &.uk {
      background-image: url(/static/i/UK-titles.png);
    }

    &.au {
      background-image: url(/static/i/AU-titles.png);
    }

    &.ca {
      background-image: url(/static/i/CA-titles.png);
    }

    &.nz {
      background-image: url(/static/i/NZ-titles.png);
    }

    &:nth-child(1) {
      background-position: 50% 6px;
    }

    &:nth-child(2) {
      background-position: 50% -62px;
    }

    &:nth-child(3) {
      background-position: 50% -128px;
    }

    .dateRange {
      color: var(--ui-mutedText);
      font-size: 1rem;
    }

  }
}

.smallList {
  display: flex;
  flex-direction: column;
  gap: 1px;
  background-color: var(--ui-divider);
  color: var(--core-ink);

  li {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    a {
      padding: 16px;
      width: 100%;
      text-align: left;

      &:hover {
        background-color: var(--background-faintBlue);
      }

      &:focus-visible {
        outline-offset: -3px;
      }

      p {
        margin: 0px;
        color: var(--ui-mutedText);
      }
    }
  }
}

.smallHeading {
  background-color: var(--background-lightBlue);
  color: var(--core-ink);
  font-size: 1rem;
  font-weight: 700;
  margin: 24px 0px 0px 0px;
  line-height: 2.5rem;
}