.GiftSubscription {
  .header {
    background-color: var(--background-lightBlue);
    position: relative;
    padding-left: 108px;

    a {
      color: var(--core-ink);
    }

    .ribbonimg {
      position: absolute;
      top: -8px;
      left: -10px;
      width: 136px;
      height: auto;
    }
  }

  .body {
    align-items: center;
    gap: 8px;
    padding: 8px;

    .decoration {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: center;
      margin: 0 auto;

      .icon {
        width: 50px;
        height: 50px;
        fill: var(--ui-mutedText);
      }
    }
  }

  .footer {
    justify-content: center;
    padding: 8px;
  }
}

.Odometer {
  display: flex;
  gap: 1px;
  margin: 0 auto;

  .digit {
    border-radius: 3px;
    background-color: var(--core-ink);
    color: #fff;
    font-size: 2rem;
    padding: .175rem;
    line-height: 2rem;

    &.notDigit {
      background-color: #fff;
      color: var(--core-ink);
    }
  }
}

.SearchAlertWrapper .Card {
  background-color: #fff !important;
}

.SignedInOdometer .Card{
 justify-content: center;
}