@use 'ui/styles/variables' as vars;

.HomeHero {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 460px;
  justify-content: center;
  align-items: center;
  padding-bottom: 120px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 95%, 50% 100%, 0% 95%);


  @include vars.mobileOnly() {
    min-height: 600px;
    padding-bottom: 60px;

    body:has(:global(#gb-banner)) & {
      min-height: 700px;
      padding-bottom: 60px;
    }

    body:has(:global(#mktg-hero-promo)) & {
      min-height: 700px;
      padding-bottom: 60px;
    }

    body:has(:global(#gb-banner)):has(:global(#mktg-hero-promo)) & {
      min-height: 780px;
    }
  }

  .HeroOverlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 56px;

    h1 {
      text-align: center;
      padding: 16px;
      color: #fff;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
      font-weight: normal;
    }
  }


  .HeroCaption {
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 300px;
    padding: 20px 20px 20px 0;
    text-align: right;
    font-size: 14px;
    line-height: 1.2;
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);

    @media (min-width: 1280px) {
      margin-right: calc((100vw - 1280px - 20px) / 2);
    }

    @include vars.mobileOnly() {
      font-size: 12px;
    }

    a {
      color: #fff;
      text-decoration: underline;
      text-wrap: nowrap;
    }
  }

  .HeroImage {
    object-fit: cover;
  }
}